import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AliPaginationComponent } from './components/ali-pagination/ali-pagination.component';
import { ConfirmationDialogService } from './components/confirmation-dialog/confirmation-dialog.service';
import { ToastService } from './components/toast/toast.service';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { MyTemplateComponent } from './components/my-template/my-template.component';
import { SbAlertModule } from './components/sb-alert/sb-alert.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AlertModule, BreadcrumbModule, CardModule, ModalModule} from './components';
import {DataFilterPipe} from './components/data-table/data-filter.pipe';
import {TodoListRemoveDirective} from './components/todo/todo-list-remove.directive';
import {TodoCardCompleteDirective} from './components/todo/todo-card-complete.directive';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';
import {SpinnerComponent} from './components/spinner/spinner.component';

import 'hammerjs';
import 'mousetrap';
import {GalleryModule} from '@ks89/angular-modal-gallery';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    GalleryModule.forRoot(),
    ClickOutsideModule,
    SbAlertModule,
    NgbPaginationModule
  ],
  exports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    GalleryModule,
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    ClickOutsideModule,
    SpinnerComponent,
    ComingSoonComponent,
    SbAlertModule,
    MyTemplateComponent,
    ConfirmationDialogComponent,
    NgbPaginationModule,
    AliPaginationComponent,
    MultiSelectDropdownComponent
  ],
  declarations: [
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    SpinnerComponent,
    ComingSoonComponent,
    MyTemplateComponent,
    ConfirmationDialogComponent,
    AliPaginationComponent,
    MultiSelectDropdownComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ToastService,
    ConfirmationDialogService,
    DatePipe
  ]
})
export class SharedModule { }
