<div class="row sb-page-root mt-0">
    <div class="col-lg-6 col-sm-6 text-left sb-page-inner">
        <div class="row sb-page-row">
            <div class="m-r-5">
                <h6 class="sub-title m-0">Show</h6>
            </div>
            <div class="m-r-10">
                <select [(ngModel)]="recordperpage" (change)="getFilterByPageSize($event.target.value)">
                    <option *ngFor="let data of recordPerPages" [value]="data">
                        {{data}}
                    </option>
                </select>
            </div>
            <div class="display-count m-r-10">
                {{'Showing '}}
                {{set1 + ' to ' + set2 + ' of '}}
                {{ dataSource?.meta?.count }}
                {{ 'Records' }}
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-sm-6 col-12 text-right sb-page-inner flex-end" *ngIf="dataSource.results?.length">
        <div class="m-r-10" *ngIf="search">
            <div class="input-group no-wrap">
                <input type="text" class="form-control searchBYSt" placeholder="search by student_id, first_name, admission_number " [value]="searchTerm" #searchVal
                style="height: 36px;">
                <div class="input-group-append" style="height: 36px;" *ngIf="searchTerm || searchVal.value">
                    <button style="border-color: #ced4da;padding: 0 15px; background: #fff;" class="btn btn-msg-send" type="button" (click)="getFilterBySearchReset()">
                      <i class="fas fa-times" style="color: red; font-size: 14px;margin: 0;"></i>
                  </button>
                </div>
                <div class="input-group-append" style="height: 36px;">
                  <button style="padding: 0 15px;" class="btn btn-primary btn-msg-send" type="button" (click)="getFilterBySearch(searchVal.value)">
                    <i class="fas fa-search" style="color: #fff;font-size: 14px;margin: 0;"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="horizontal-scroll" *ngIf="!search">
            <ngb-pagination [collectionSize]="dataSource?.meta?.count" (pageChange)="getFilterByPage($event)" [(page)]="currentPage" [maxSize]="3" [pageSize]="recordperpage" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
        </div>
    </div>
</div>