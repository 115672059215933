import { Observable } from 'rxjs';
import { AuthService } from './../../../../../auth/auth.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sb-alert',
  templateUrl: './sb-alert-component.component.html',
  styleUrls: ['./sb-alert-component.component.scss']
})
export class SbAlertComponentComponent implements OnInit {

  // @Input() type: string;
  // @Input() dismiss: string;
  sbAlert$: Observable<any>;
  sbAlert: any = [];
  // public dismissAlert(element): void {
  //   element.parentElement.removeChild(element);
  // }
  constructor(
    private authService: AuthService
  ) {
    this.sbAlert$ = this.authService.alerts$;
    this.sbAlert$.subscribe(res => {
      this.sbAlert = res;
    });
  }

  ngOnInit(): void {
  }
  dismissAlert(): void {
    this.authService.setAlerts([]);
  }

}
