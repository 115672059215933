import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    let token: any = null;
    token = localStorage.getItem('token');
    if(route?.queryParams?.username && route?.queryParams?.password && !token){
      let params = route.queryParams;
        let user = {
          username:params.username,
          password:params.password,
        }
        await this.onSubmit(user)
        return
    }
    if (token) {
      return true;
      // let status: any = false;
      // await this.authService.getMethod('/users/userinfo').toPromise().then(res => {
      //   this.authService.setUserInfo(res);
      //   if (res.status === true) {
      //     status = true;
      //   }
      // }, error => { this.authService.errorMessage(this, error); }
      // );
      // if (status === true) {
      //   return true;
      // }
    }
    localStorage.removeItem('token');
    this.router.navigate(['/auth/signin']);
    return false;
  }
  onSubmit(credentials): any {
    if (!credentials?.username || !credentials?.password) {
      return false;
    }
    localStorage.setItem('chairman',credentials.username)
    this.authService.login(credentials).subscribe(
      res => {
        if (res.status === true) {
          if (res.data && res.data[0].access) {
            this.authService.showSnackbar('Login Successfull', 'success');
            localStorage.setItem('token', res.data[0].access);
            localStorage.setItem('refresh-token', res.data[0].refresh);
            window.location.href = '/dashboard/home';
          }
        } else {
          this.authService.showSnackbar(res.message, 'error');
        }
      },
      error => {
        console.log('Error', error);
        console.log(error.status);
        if (error.status && error.status === 400) {
          this.authService.showSnackbar('Please check the username and password', 'error');
        } else {
          this.authService.errorMessage(this, error);
        }
      }
    );
  }
}
