import { Router } from '@angular/router';
import { AuthService } from './../../../auth/auth.service';
import {Component, NgZone, OnInit} from '@angular/core';
import {DattaConfig} from '../../../app-config';
import {Location} from '@angular/common';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public dattaConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;
  isHover:boolean
  constructor(
    private zone: NgZone,
    private location: Location,
    private authService: AuthService,
    private router: Router
  ) {
    this.dattaConfig = DattaConfig.config;

    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }

    if (current_url === this.location['_baseHref'] + '/layout/collapse-menu' || current_url === this.location['_baseHref'] + '/layout/box') {
      this.dattaConfig['collapse-menu'] = true;
    }

    this.windowWidth = window.innerWidth;
    this.navCollapsed = (this.windowWidth >= 992) ? this.dattaConfig['collapse-menu'] : false;
    this.isHover = (this.windowWidth >= 992) ? this.dattaConfig['collapse-menu'] : false;
    this.navCollapsedMob = false;
  }

  ngOnInit() {
    if (localStorage.getItem('currentModule')) {
      this.authService.setModule(localStorage.getItem('currentModule'));
    } else {
      localStorage.removeItem('currentModule');
      this.authService.setNavigations(this.authService.defaultNavigationItems());
      this.router.navigate(['/dashboard/home']);
    }
  }

  navMobClick() {
    if (this.navCollapsedMob && !(document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
      this.navCollapsedMob = !this.navCollapsedMob;
      setTimeout(() => {
        this.navCollapsedMob = !this.navCollapsedMob;
      }, 100);
    } else {
      this.navCollapsedMob = !this.navCollapsedMob;
    }
  }
  onNavCollapse(){
    this.isHover = !this.isHover;
    setTimeout(() => {
      this.navCollapsed = !this.navCollapsed;
    }, 200);
  }
}
