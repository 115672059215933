import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecampes-footer',
  templateUrl: './ecampes-footer.component.html',
  styleUrls: ['./ecampes-footer.component.scss']
})
export class EcampesFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
