import { AuthService } from './../../../../../auth/auth.service';
import { environment } from './../../../../../../environments/environment';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-nav-logo',
  templateUrl: './nav-logo.component.html',
  styleUrls: ['./nav-logo.component.scss']
})
export class NavLogoComponent implements OnInit {
  @Input() navCollapsed: boolean;
  @Output() onNavCollapse = new EventEmitter();
  public windowWidth: number;
  schoolDetails: any = environment.school_details;
  institutionDatas: any = [];
  spinner: any = false;

  constructor(
    private authService: AuthService
  ) {
    this.windowWidth = window.innerWidth;
  }

  ngOnInit(): any {
    this.getInstitutionDatas();
  }
  getInstitutionDatas(): any {
    this.spinner = true;
    this.authService.getMethod('/master/profile/list/', null).subscribe(
      res => {
        if (res.status === true) {
          if (res.data && res.data[0].results && res.data[0].results[0]) {
            this.institutionDatas = res.data[0].results[0];
          }
        } else {
          this.authService.showSnackbar(res.message, 'error');
        }
        this.spinner = false;
      }, error => {
        this.authService.errorMessage(this, error);
      }
    );
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.navCollapsed = !this.navCollapsed;
      this.onNavCollapse.emit();
    }
  }

}
