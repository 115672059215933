import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbAlertComponentComponent } from './sb-alert-component/sb-alert-component.component';



@NgModule({
  declarations: [SbAlertComponentComponent],
  imports: [
    CommonModule
  ],
  exports: [SbAlertComponentComponent]
})
export class SbAlertModule { }
