import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardNonAdmin implements CanActivate {
  constructor(
    private router: Router
  ) { }
  async canActivate() {
    localStorage.removeItem('token');
    const token = localStorage.getItem('token');
    if (!token) {
      return true;
    } else {
      this.router.navigate(['/dashboard/home']);
      return false;
    }
  }
}
