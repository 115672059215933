<app-navigation class="pcoded-navbar" [ngClass]="{'navbar-collapsed' : navCollapsed,'isHover':isHover, 'theme-horizontal': this.dattaConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}" (onNavCollapse)="onNavCollapse()" (onNavCollapsedMob)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light" (onNavCollapsedMob)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container">
  <div class="pcoded-wrapper">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <app-breadcrumb></app-breadcrumb>
        <app-sb-alert></app-sb-alert>
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-configuration></app-configuration>
